import React from 'react'
import { getLocaleSlugs } from './src/util/helper'
import i18n from 'i18next'
import './src/styles/global.css'

export function wrapPageElement({ element }) {
  const Layout = element.type.Layout ?? React.Fragment
  return (
      <Layout>{element}</Layout>
  )
}

export function shouldUpdateScroll({
  routerProps,
  prevRouterProps
}) {
  // Prevent scrolling to top when changing filters in the listings page
  const listingPageSlugs = getLocaleSlugs('listings')
  const listingPageSlug = listingPageSlugs[i18n.language]
  const nextPath = routerProps?.location.pathname
  const currentPath = prevRouterProps?.location.pathname
  if (nextPath === currentPath && currentPath.match(RegExp(`${listingPageSlug}\/?$`))) {
    return false
  }
  return true
}