import SLUG_CONFIG from '../i18n/slugs'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import i18n from 'i18next'
import round from 'lodash/round'
import AllCountries from 'react-intl-tel-input/dist/components/AllCountries'


const COUNTRY = process.env.GATSBY_COUNTRY
const SITE = process.env.GATSBY_SITE

const languages = {
  fi: 'Suomi',
  en: 'English',
  sv: 'Svenska',
  nb: 'Norsk',
}

const currencies = {
  'EUR': '€',
  'SEK': 'kr',
  'NOK': 'kr'
}

export const getLocaleSlugs = (page) => {
  const supportedLocales = process.env.GATSBY_LOCALES.split(',')

  const localeSlugs = {}
  supportedLocales.forEach((lang) => {
    localeSlugs[lang] =
      page in SLUG_CONFIG[SITE] ? SLUG_CONFIG[SITE][page].slug[lang] : ''
  })
  return localeSlugs
}

export const getPath = (page) => {
  const currentLocale = i18n.language
  const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

  const slug = SLUG_CONFIG[COUNTRY][page]?.slug[currentLocale]
  const locale = currentLocale !== defaultLocale ? `/${currentLocale}` : ''

  if (slug) {
    return `${locale}/${slug}/`
  }

  return locale ? locale : '/'
}

export const getLanguage = (locale) => {
  return languages[locale]
}

export const formatCurrency = (price, currency, shouldRound=true, minimumFractionDigits=2) => {
  if (shouldRound) {
    price = round(price)
  }
  const value = new Intl.NumberFormat('fi-FI', { minimumFractionDigits: minimumFractionDigits, style: 'decimal' }).format(price)

  if (currency) {
    const currencySymbol = currencies[currency] || currency;
    return `${value} ${currencySymbol}`
  }

  return value
}

export const capitalize = (value) => {
  if (!value) {
    return value
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const encodeUtf8 = (input) => {
  return unescape(encodeURIComponent(input))
}

export const generate = () => {
  const secret = encodeUtf8(process.env.API_SHARED_SECRET)
  const date = new Date()
  const timestamp = date.toISOString().split('.')[0]
  const hmacDigest = hmacSHA256(timestamp, secret)
  return `${timestamp}_${hmacDigest}`
}

/*
NOTE: Change internal links in Contentful to start with / instead of full URL (and tell content updaters how to enter internal links in the future). After the links have been changed and everyone is on the same page, this function can be removed from the project.
*/

// Try to return just the pathname for internal links for Gatsby internal linking
export const createInternalLink = (href) => {
  if (typeof href !== 'string') return href

  return href.replace(process.env.GATSBY_SITE_URL, '')
}

export const trackFbq = (eventType, eventName) => {
  if (typeof fbq !== 'undefined') {
    fbq(eventType, eventName)
    return true
  }
  return false
}

export const trackPintrk = (eventType, eventName) => {
  if (typeof pintrk !== 'undefined') {
    pintrk(eventType, eventName)
    return true
  }
  return false
}

export const trackGa = (action, type, source, trigger, name) => {
  if (typeof ga !== 'undefined') {
    ga(action, type, source, trigger, name)
    return true
  }
  return false
}

export const trackGtag = (action, type, options) => {
  if (typeof gtag !== 'undefined') {
    gtag(action, type, options)
    return true
  }
  return false
}

export const trackObApi = (eventType, eventName) => {
  if (typeof obApi !== 'undefined') {
    obApi(eventType, eventName)
    return true
  }
  return false
}

export const trackTaboola = (eventName, id) => {
  if (typeof _tfa !== 'undefined') {
    _tfa.push({notify: 'event', name: eventName, id: id})
    return true
  }
  return false
}

export const convertPhoneNumberToIntl = (phoneNumber, country=COUNTRY) => {
  country = country.replace('LEAD_', '')
  phoneNumber = phoneNumber.replace(/[\s-]/g, '')
  if (phoneNumber.startsWith('+')) {
    return phoneNumber
  }
  const countriesData = AllCountries.getCountries()
  for (const countryData of countriesData) {
    if (country.toLowerCase() === countryData.iso2) {
      return `+${countryData.dialCode}${phoneNumber.substring(1)}`
    }
  }
  return phoneNumber
}

export const isLandlinePhoneNumber = (intlPhoneNumber, country=COUNTRY) => {
  country = country.replace('LEAD_', '')
  if (!intlPhoneNumber.startsWith('+')) {
    intlPhoneNumber = convertPhoneNumberToIntl(phoneNumber, country)
  }
  return !([
    '+467',  // Sweden
    '+479', '+474', '+4758', '+4759', // Norway
    '+3585', '+3584', // Finland
  ].some(x => intlPhoneNumber.startsWith(x)))
}
