const slugs = {
  FI: {
    blog: {
      path: '/blog/',
      slug: {
        fi: 'blog',
        en: 'blog',
        sv: 'blog',
      },
    },
    listings: {
      path: '/listings/',
      slug: {
        fi: 'asunnot',
        en: 'apartments',
        sv: 'bostader',
      },
    },
    privacy: {
      path: '/privacy/',
      slug: {
        fi: 'yksityisyys',
        en: 'privacy',
        sv: 'datasakerhet',
      },
    },
    neighbourhoods: {
      path: '/neighbourhoods/',
      slug: {
        fi: 'asuinaluevertailu',
        en: 'neighbourhoods',
        sv: 'jamforelse-av-bostadsomraden',
      },
    },
    property: {
      path: '/property/[slug]/',
      slug: {
        fi: 'kohde',
        en: 'property',
        sv: 'objekt',
      },
      matchPath: {
        fi: '/kohde/:slug',
        en: '/property/:slug',
        sv: '/objekt/:slug',
      },
    },
    areas: {
      path: '/areas/',
      slug: {
        fi: 'toimialueet',
        en: 'areas',
        sv: 'omraden',
      },
    },
    houses: {
      path: '/houses/[slug]/',
      slug: {
        fi: 'talot',
        en: 'houses',
        sv: 'hus',
      },
      matchPath: {
        fi: '/talot/:slug',
        en: '/houses/:slug',
        sv: '/hus/:slug',
      },
    },
    hitas: {
      path: '/hitas/',
      slug: {
        fi: 'hitas',
        en: 'hitas',
        sv: 'hitas',
      },
    },
  },
  SE: {
    blog: {
      path: '/blog/',
      slug: {
        en: 'blog',
        sv: 'blogg',
      },
    },
    listings: {
      path: '/listings/',
      slug: {
        en: 'apartments',
        sv: 'bostader',
      },
    },
    privacy: {
      path: '/privacy/',
      slug: {
        en: 'privacy',
        sv: 'datasakerhet',
      },
    },
    property: {
      path: '/property/[slug]/',
      slug: {
        en: 'property',
        sv: 'objekt',
      },
      matchPath: {
        en: '/property/:slug',
        sv: '/objekt/:slug',
      },
    },
    areas: {
      path: '/areas/',
      slug: {
        en: 'areas',
        sv: 'omraden',
      },
    },
  },
  NO: {},
  LEAD_SE: {
    lead_contact_request_thanks: {
      path: '/lead_contact_request_thanks/',
      slug: {
        en: 'request-contact',
        sv: 'begar-kontakt'
      }
    }
  },
  LEAD_NO: {},
}

module.exports = slugs
